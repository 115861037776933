import React from 'react'
import Card from './Card'
import Carousel from 'react-bootstrap/Carousel';

class CarouselCard extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const cards = []

        for (const article of this.props.articles) {
            cards.push(
                <Carousel.Item key={article.node.wordpress_id} >
                    <Card article={article} key={`article__${article.node.wordpress_id}`} />
                </Carousel.Item>)
        }

        return (
            <Carousel>
                {cards}
            </Carousel>
        )
    }
}

export default CarouselCard


