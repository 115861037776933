import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import CarouselCard from '../components/Carousel'

const Portfolio = () => (
  <Layout>
    <StaticQuery
      query={graphql`
        query {
          allWordpressPost {
            edges {
              node {
                wordpress_id
                title
                excerpt
                content
                categories {
                  id
                  name
                }
                featured_media {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 400, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }`}
      render={data => (
          <div className="carousel">
            <CarouselCard articles={data.allWordpressPost.edges} />
          </div>
      )}
    />
  </Layout>
)

export default Portfolio
