import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { CategoriesIcons } from '../components/Categories'




const Card = ({ article }) => {
  const sources = article.node.featured_media.localFile.childImageSharp.fluid;
  

    return (
      <div className="card mt-0">
        <h2 className="card-title mb-3">
          {article.node.title}
        
        </h2>
        <div className="row">
        <div className="col-8 text-center m-auto"><Img fluid={sources} />
        </div>
        <div className="card-block col-4">
          <div>
            <CategoriesIcons categories={article.node.categories} />
          </div>
          </div>
          
          <div className="align-middle mt-5">
            <div dangerouslySetInnerHTML={{ __html: article.node.excerpt }}></div>
          </div>
        </div>
        
    
      </div>


      



      
      
    )
}

export default Card
