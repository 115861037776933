import React from 'react'
import { 
    OverlayTrigger,
    Tooltip }from 'react-bootstrap'
import { 
    FaReact, 
    FaPhp, 
    FaVuejs, 
    FaWordpressSimple,
    FaNode,
    FaNodeJs,
    FaGit,
    FaCpanel,
    FaSass,
    FaAdobe,
    FaCss3Alt,
    FaHtml5} from 'react-icons/fa'
import { RiGatsbyLine} from "react-icons/ri"
import { DiMongodb, DiPostgresql, DiRuby} from "react-icons/di"



    const CategoriesIcons = ({ categories }) => {
    const iconList = []

        for ( const category of categories) {
            if (category.name === "PHP") {
                iconList.push(
                <div
                    key={category.id} 
                    style={{ color: "#777BB3" }} className="categoryIcon  p-1 m-1"
                    >
                    <OverlayTrigger
                    placement={'right'}
                    overlay={
                        <Tooltip>
                        PHP
                        </Tooltip>
                    }
                    >
                        <FaPhp />
                    </OverlayTrigger>
                </div>);
            } else if (category.name === "React") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#60dbfb" }}><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        React
                    </Tooltip>
                }
            >
                <FaReact />
            </OverlayTrigger></div>);
            } else if (category.name === "Vue") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#4FC08D"}}><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        Vue
                    </Tooltip>
                }
            >
                <FaVuejs />
            </OverlayTrigger></div>);
            } else if (category.name === "Wordpress") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#0e769d" }}><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        Wordpress
                    </Tooltip>
                }
            >
                <FaWordpressSimple />
            </OverlayTrigger></div>);
            } else if (category.name === "Cpanel hosting") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#EC6126" }} ><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        Cpanel
                    </Tooltip>
                }
            >
                <FaCpanel />
            </OverlayTrigger></div >);
            } else if (category.name === "Graphic Design") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#ED1C24" }}><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        Adobe
                    </Tooltip>
                }
            >
                <FaAdobe />
            </OverlayTrigger></div>);
            } else if (category.name === "Node") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#6CC24A" }}><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        Node
                    </Tooltip>
                }
            >
                <FaNode />
            </OverlayTrigger></div>);
            } else if (category.name === "Git") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#DE4C36" }}><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        Git
                    </Tooltip>
                }
            >
                <FaGit />
            </OverlayTrigger></div>);
            } else if (category.name === "Sass") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#CC6699" }}><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        Sass
                    </Tooltip>
                }
            >
                <FaSass />
            </OverlayTrigger></div>);
            } else if (category.name === "Gatsby") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#66339A" }}><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        Gatsby
                    </Tooltip>
                }
            >
                <RiGatsbyLine />
            </OverlayTrigger></div>);
            } else if (category.name === "HTML") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#DE4B26" }}><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        HTML5
                    </Tooltip>
                }
            >
                <FaHtml5 />
            </OverlayTrigger></div>);
            } else if (category.name === "CSS") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#1592D6" }}><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        Css3
                    </Tooltip>
                }
            >
                <FaCss3Alt />
            </OverlayTrigger></div>);
            } else if (category.name === "MongoDB") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#4E9547" }}><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        MogoDB
                    </Tooltip>
                }
            >
                <DiMongodb />
            </OverlayTrigger></div>);
            } else if (category.name === "PostgreSQL") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#31638C" }}><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        PostgreSQL
                    </Tooltip>
                }
            >
                <DiPostgresql />
            </OverlayTrigger></div>);
            } else if (category.name === "Javascript") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#E9B924" }}><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        Javascript
                    </Tooltip>
                }
            >
                <FaNodeJs />
            </OverlayTrigger></div>);
            } else if (category.name === "Ruby") {
                iconList.push(<div key={category.id} className="categoryIcon  p-1 m-1" style={{ color: "#D91404" }}><OverlayTrigger
                placement={'right'}
                overlay={
                    <Tooltip>
                        Ruby
                    </Tooltip>
                }
            >
                    <DiRuby />
            </OverlayTrigger></div>);
            } else {
                console.log(`we have an unamed category: ${category.name}`)
            }
        }
        return (
            <div className="d-flex flex-column justify-content-between h3">
                {iconList}
            </div>
        )
    }

export {
    CategoriesIcons
}

